/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew ToB向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GoalBeforeCoopyOutputResponse } from '../domains';
// @ts-ignore
import { GoalBeforeCopyFormResponse } from '../domains';
// @ts-ignore
import { GoalCreateQscCheckListResponse } from '../domains';
// @ts-ignore
import { GoalCreateQscCheckOutputListResponse } from '../domains';
// @ts-ignore
import { GoalResultInfoOutputResponse } from '../domains';
// @ts-ignore
import { QscShopQuestionGoalCreateFormResponse } from '../domains';
// @ts-ignore
import { QscShopQuestionGoalCreateOutputResponse } from '../domains';
// @ts-ignore
import { QuestionCategoryListOutputResponse } from '../domains';
// @ts-ignore
import { ShopCategoryGoalResultOutputResponse } from '../domains';
// @ts-ignore
import { ShopCategoryGoalSettingUpdateFormResponse } from '../domains';
// @ts-ignore
import { ShopCategoryGoalSettingUpdateOutputResponse } from '../domains';
// @ts-ignore
import { ShopGoalResultOutputResponse } from '../domains';
// @ts-ignore
import { ShopGoalSettingUpdateFormResponse } from '../domains';
// @ts-ignore
import { ShopGoalSettingUpdateOutputResponse } from '../domains';
// @ts-ignore
import { ShopQuestionCategoryGoalCreateFormResponse } from '../domains';
// @ts-ignore
import { ShopQuestionCategoryGoalCreateOutputResponse } from '../domains';
// @ts-ignore
import { ShopQuestionGoalCreateFormResponse } from '../domains';
// @ts-ignore
import { ShopQuestionGoalCreateOutputResponse } from '../domains';
/**
 * GoalApi - axios parameter creator
 * @export
 */
export const GoalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 改善目標コピー
         * @summary 改善目標コピー
         * @param {GoalBeforeCopyFormResponse} goalBeforeCopyFormResponse 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        goalBeforeCopy: async (goalBeforeCopyFormResponse: GoalBeforeCopyFormResponse, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'goalBeforeCopyFormResponse' is not null or undefined
            assertParamExists('goalBeforeCopy', 'goalBeforeCopyFormResponse', goalBeforeCopyFormResponse)
            const localVarPath = `/api/goal-before-copy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(goalBeforeCopyFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * QSCチェック設問目標を設定する
         * @summary QSCチェック設問目標設定
         * @param {Array<GoalCreateQscCheckListResponse>} goalCreateQscCheckListResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        goalCreateQscCheck: async (goalCreateQscCheckListResponse: Array<GoalCreateQscCheckListResponse>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'goalCreateQscCheckListResponse' is not null or undefined
            assertParamExists('goalCreateQscCheck', 'goalCreateQscCheckListResponse', goalCreateQscCheckListResponse)
            const localVarPath = `/api/goal-create-qsc-check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(goalCreateQscCheckListResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 目標設定・達成状況を取得する
         * @summary 目標設定・達成状況取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        goalResultInfo: async (id?: string, ym?: string, monitorId?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/goal-result-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 目標設定・達成状況をExcel取得する
         * @summary 目標設定・達成状況Excel取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        goalResultInfoExcel: async (id?: string, ym?: string, monitorId?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/goal-result-info-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 設問別積上げ目標を設定する
         * @summary 設問別積上げ目標設定
         * @param {Array<QscShopQuestionGoalCreateFormResponse>} qscShopQuestionGoalCreateFormResponse 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        qscShopQstGoalCreate: async (qscShopQuestionGoalCreateFormResponse: Array<QscShopQuestionGoalCreateFormResponse>, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'qscShopQuestionGoalCreateFormResponse' is not null or undefined
            assertParamExists('qscShopQstGoalCreate', 'qscShopQuestionGoalCreateFormResponse', qscShopQuestionGoalCreateFormResponse)
            const localVarPath = `/api/qsc-shop-goal-question-create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(qscShopQuestionGoalCreateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 目標設定設問カテゴリ一覧を取得する
         * @summary 目標設定設問カテゴリ一覧取得
         * @param {number} [monitorId] モニターベースID
         * @param {string} [ym] 
         * @param {string} [id] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionCategoryList1: async (monitorId?: number, ym?: string, id?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/goal-question-category-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 設問カテゴリ結果(本部 or 店舗)を取得する
         * @summary 設問カテゴリ目標結果取得(本部 or 店舗)
         * @param {string} [goalPage] 
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [cat] 
         * @param {string} [achieved] 
         * @param {string} [fromDiff] 
         * @param {string} [toDiff] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopCategoryGoalResultList: async (goalPage?: string, id?: string, ym?: string, monitorId?: string, cat?: string, achieved?: string, fromDiff?: string, toDiff?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shop-category-goal-result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (goalPage !== undefined) {
                localVarQueryParameter['goalPage'] = goalPage;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (cat !== undefined) {
                localVarQueryParameter['cat'] = cat;
            }

            if (achieved !== undefined) {
                localVarQueryParameter['achieved'] = achieved;
            }

            if (fromDiff !== undefined) {
                localVarQueryParameter['fromDiff'] = fromDiff;
            }

            if (toDiff !== undefined) {
                localVarQueryParameter['toDiff'] = toDiff;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 目標（設問）を登録更新削除する
         * @summary 目標設定登録更新削除（設問カテゴリ）
         * @param {ShopCategoryGoalSettingUpdateFormResponse} shopCategoryGoalSettingUpdateFormResponse 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopCategoryGoalSettingUpdate: async (shopCategoryGoalSettingUpdateFormResponse: ShopCategoryGoalSettingUpdateFormResponse, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shopCategoryGoalSettingUpdateFormResponse' is not null or undefined
            assertParamExists('shopCategoryGoalSettingUpdate', 'shopCategoryGoalSettingUpdateFormResponse', shopCategoryGoalSettingUpdateFormResponse)
            const localVarPath = `/api/shop-category-goal-setting-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shopCategoryGoalSettingUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 設問目標結果(店舗)を取得する
         * @summary 設問目標結果(店舗)取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopGoalResultList: async (id?: string, ym?: string, monitorId?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shop-goal-result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 目標（設問）を登録更新削除する
         * @summary 目標設定登録更新削除（設問）
         * @param {ShopGoalSettingUpdateFormResponse} shopGoalSettingUpdateFormResponse 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopGoalSettingUpdate: async (shopGoalSettingUpdateFormResponse: ShopGoalSettingUpdateFormResponse, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shopGoalSettingUpdateFormResponse' is not null or undefined
            assertParamExists('shopGoalSettingUpdate', 'shopGoalSettingUpdateFormResponse', shopGoalSettingUpdateFormResponse)
            const localVarPath = `/api/shop-goal-setting-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shopGoalSettingUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 設問カテゴリ目標を設定する
         * @summary 設問カテゴリ目標設定
         * @param {Array<ShopQuestionCategoryGoalCreateFormResponse>} shopQuestionCategoryGoalCreateFormResponse 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopQstCatGoalCreate: async (shopQuestionCategoryGoalCreateFormResponse: Array<ShopQuestionCategoryGoalCreateFormResponse>, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shopQuestionCategoryGoalCreateFormResponse' is not null or undefined
            assertParamExists('shopQstCatGoalCreate', 'shopQuestionCategoryGoalCreateFormResponse', shopQuestionCategoryGoalCreateFormResponse)
            const localVarPath = `/api/shop-question-cat-goal-create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shopQuestionCategoryGoalCreateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 設問別目標を設定する
         * @summary 設問別目標設定
         * @param {Array<ShopQuestionGoalCreateFormResponse>} shopQuestionGoalCreateFormResponse 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopQstGoalCreate: async (shopQuestionGoalCreateFormResponse: Array<ShopQuestionGoalCreateFormResponse>, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shopQuestionGoalCreateFormResponse' is not null or undefined
            assertParamExists('shopQstGoalCreate', 'shopQuestionGoalCreateFormResponse', shopQuestionGoalCreateFormResponse)
            const localVarPath = `/api/shop-goal-question-create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shopQuestionGoalCreateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GoalApi - functional programming interface
 * @export
 */
export const GoalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GoalApiAxiosParamCreator(configuration)
    return {
        /**
         * 改善目標コピー
         * @summary 改善目標コピー
         * @param {GoalBeforeCopyFormResponse} goalBeforeCopyFormResponse 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async goalBeforeCopy(goalBeforeCopyFormResponse: GoalBeforeCopyFormResponse, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoalBeforeCoopyOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.goalBeforeCopy(goalBeforeCopyFormResponse, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * QSCチェック設問目標を設定する
         * @summary QSCチェック設問目標設定
         * @param {Array<GoalCreateQscCheckListResponse>} goalCreateQscCheckListResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async goalCreateQscCheck(goalCreateQscCheckListResponse: Array<GoalCreateQscCheckListResponse>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoalCreateQscCheckOutputListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.goalCreateQscCheck(goalCreateQscCheckListResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 目標設定・達成状況を取得する
         * @summary 目標設定・達成状況取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async goalResultInfo(id?: string, ym?: string, monitorId?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoalResultInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.goalResultInfo(id, ym, monitorId, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 目標設定・達成状況をExcel取得する
         * @summary 目標設定・達成状況Excel取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async goalResultInfoExcel(id?: string, ym?: string, monitorId?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.goalResultInfoExcel(id, ym, monitorId, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 設問別積上げ目標を設定する
         * @summary 設問別積上げ目標設定
         * @param {Array<QscShopQuestionGoalCreateFormResponse>} qscShopQuestionGoalCreateFormResponse 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async qscShopQstGoalCreate(qscShopQuestionGoalCreateFormResponse: Array<QscShopQuestionGoalCreateFormResponse>, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QscShopQuestionGoalCreateOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.qscShopQstGoalCreate(qscShopQuestionGoalCreateFormResponse, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 目標設定設問カテゴリ一覧を取得する
         * @summary 目標設定設問カテゴリ一覧取得
         * @param {number} [monitorId] モニターベースID
         * @param {string} [ym] 
         * @param {string} [id] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionCategoryList1(monitorId?: number, ym?: string, id?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuestionCategoryListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionCategoryList1(monitorId, ym, id, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 設問カテゴリ結果(本部 or 店舗)を取得する
         * @summary 設問カテゴリ目標結果取得(本部 or 店舗)
         * @param {string} [goalPage] 
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [cat] 
         * @param {string} [achieved] 
         * @param {string} [fromDiff] 
         * @param {string} [toDiff] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopCategoryGoalResultList(goalPage?: string, id?: string, ym?: string, monitorId?: string, cat?: string, achieved?: string, fromDiff?: string, toDiff?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShopCategoryGoalResultOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopCategoryGoalResultList(goalPage, id, ym, monitorId, cat, achieved, fromDiff, toDiff, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 目標（設問）を登録更新削除する
         * @summary 目標設定登録更新削除（設問カテゴリ）
         * @param {ShopCategoryGoalSettingUpdateFormResponse} shopCategoryGoalSettingUpdateFormResponse 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopCategoryGoalSettingUpdate(shopCategoryGoalSettingUpdateFormResponse: ShopCategoryGoalSettingUpdateFormResponse, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShopCategoryGoalSettingUpdateOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopCategoryGoalSettingUpdate(shopCategoryGoalSettingUpdateFormResponse, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 設問目標結果(店舗)を取得する
         * @summary 設問目標結果(店舗)取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopGoalResultList(id?: string, ym?: string, monitorId?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShopGoalResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopGoalResultList(id, ym, monitorId, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 目標（設問）を登録更新削除する
         * @summary 目標設定登録更新削除（設問）
         * @param {ShopGoalSettingUpdateFormResponse} shopGoalSettingUpdateFormResponse 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopGoalSettingUpdate(shopGoalSettingUpdateFormResponse: ShopGoalSettingUpdateFormResponse, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShopGoalSettingUpdateOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopGoalSettingUpdate(shopGoalSettingUpdateFormResponse, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 設問カテゴリ目標を設定する
         * @summary 設問カテゴリ目標設定
         * @param {Array<ShopQuestionCategoryGoalCreateFormResponse>} shopQuestionCategoryGoalCreateFormResponse 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopQstCatGoalCreate(shopQuestionCategoryGoalCreateFormResponse: Array<ShopQuestionCategoryGoalCreateFormResponse>, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShopQuestionCategoryGoalCreateOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopQstCatGoalCreate(shopQuestionCategoryGoalCreateFormResponse, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 設問別目標を設定する
         * @summary 設問別目標設定
         * @param {Array<ShopQuestionGoalCreateFormResponse>} shopQuestionGoalCreateFormResponse 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopQstGoalCreate(shopQuestionGoalCreateFormResponse: Array<ShopQuestionGoalCreateFormResponse>, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShopQuestionGoalCreateOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopQstGoalCreate(shopQuestionGoalCreateFormResponse, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GoalApi - factory interface
 * @export
 */
export const GoalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GoalApiFp(configuration)
    return {
        /**
         * 改善目標コピー
         * @summary 改善目標コピー
         * @param {GoalBeforeCopyFormResponse} goalBeforeCopyFormResponse 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        goalBeforeCopy(goalBeforeCopyFormResponse: GoalBeforeCopyFormResponse, allClient?: string, options?: any): AxiosPromise<GoalBeforeCoopyOutputResponse> {
            return localVarFp.goalBeforeCopy(goalBeforeCopyFormResponse, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * QSCチェック設問目標を設定する
         * @summary QSCチェック設問目標設定
         * @param {Array<GoalCreateQscCheckListResponse>} goalCreateQscCheckListResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        goalCreateQscCheck(goalCreateQscCheckListResponse: Array<GoalCreateQscCheckListResponse>, options?: any): AxiosPromise<GoalCreateQscCheckOutputListResponse> {
            return localVarFp.goalCreateQscCheck(goalCreateQscCheckListResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 目標設定・達成状況を取得する
         * @summary 目標設定・達成状況取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        goalResultInfo(id?: string, ym?: string, monitorId?: string, allClient?: string, options?: any): AxiosPromise<GoalResultInfoOutputResponse> {
            return localVarFp.goalResultInfo(id, ym, monitorId, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 目標設定・達成状況をExcel取得する
         * @summary 目標設定・達成状況Excel取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        goalResultInfoExcel(id?: string, ym?: string, monitorId?: string, allClient?: string, options?: any): AxiosPromise<string> {
            return localVarFp.goalResultInfoExcel(id, ym, monitorId, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 設問別積上げ目標を設定する
         * @summary 設問別積上げ目標設定
         * @param {Array<QscShopQuestionGoalCreateFormResponse>} qscShopQuestionGoalCreateFormResponse 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        qscShopQstGoalCreate(qscShopQuestionGoalCreateFormResponse: Array<QscShopQuestionGoalCreateFormResponse>, allClient?: string, options?: any): AxiosPromise<QscShopQuestionGoalCreateOutputResponse> {
            return localVarFp.qscShopQstGoalCreate(qscShopQuestionGoalCreateFormResponse, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 目標設定設問カテゴリ一覧を取得する
         * @summary 目標設定設問カテゴリ一覧取得
         * @param {number} [monitorId] モニターベースID
         * @param {string} [ym] 
         * @param {string} [id] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionCategoryList1(monitorId?: number, ym?: string, id?: string, allClient?: string, options?: any): AxiosPromise<Array<QuestionCategoryListOutputResponse>> {
            return localVarFp.questionCategoryList1(monitorId, ym, id, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 設問カテゴリ結果(本部 or 店舗)を取得する
         * @summary 設問カテゴリ目標結果取得(本部 or 店舗)
         * @param {string} [goalPage] 
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [cat] 
         * @param {string} [achieved] 
         * @param {string} [fromDiff] 
         * @param {string} [toDiff] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopCategoryGoalResultList(goalPage?: string, id?: string, ym?: string, monitorId?: string, cat?: string, achieved?: string, fromDiff?: string, toDiff?: string, allClient?: string, options?: any): AxiosPromise<Array<ShopCategoryGoalResultOutputResponse>> {
            return localVarFp.shopCategoryGoalResultList(goalPage, id, ym, monitorId, cat, achieved, fromDiff, toDiff, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 目標（設問）を登録更新削除する
         * @summary 目標設定登録更新削除（設問カテゴリ）
         * @param {ShopCategoryGoalSettingUpdateFormResponse} shopCategoryGoalSettingUpdateFormResponse 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopCategoryGoalSettingUpdate(shopCategoryGoalSettingUpdateFormResponse: ShopCategoryGoalSettingUpdateFormResponse, allClient?: string, options?: any): AxiosPromise<ShopCategoryGoalSettingUpdateOutputResponse> {
            return localVarFp.shopCategoryGoalSettingUpdate(shopCategoryGoalSettingUpdateFormResponse, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 設問目標結果(店舗)を取得する
         * @summary 設問目標結果(店舗)取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopGoalResultList(id?: string, ym?: string, monitorId?: string, allClient?: string, options?: any): AxiosPromise<ShopGoalResultOutputResponse> {
            return localVarFp.shopGoalResultList(id, ym, monitorId, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 目標（設問）を登録更新削除する
         * @summary 目標設定登録更新削除（設問）
         * @param {ShopGoalSettingUpdateFormResponse} shopGoalSettingUpdateFormResponse 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopGoalSettingUpdate(shopGoalSettingUpdateFormResponse: ShopGoalSettingUpdateFormResponse, allClient?: string, options?: any): AxiosPromise<ShopGoalSettingUpdateOutputResponse> {
            return localVarFp.shopGoalSettingUpdate(shopGoalSettingUpdateFormResponse, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 設問カテゴリ目標を設定する
         * @summary 設問カテゴリ目標設定
         * @param {Array<ShopQuestionCategoryGoalCreateFormResponse>} shopQuestionCategoryGoalCreateFormResponse 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopQstCatGoalCreate(shopQuestionCategoryGoalCreateFormResponse: Array<ShopQuestionCategoryGoalCreateFormResponse>, allClient?: string, options?: any): AxiosPromise<ShopQuestionCategoryGoalCreateOutputResponse> {
            return localVarFp.shopQstCatGoalCreate(shopQuestionCategoryGoalCreateFormResponse, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 設問別目標を設定する
         * @summary 設問別目標設定
         * @param {Array<ShopQuestionGoalCreateFormResponse>} shopQuestionGoalCreateFormResponse 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopQstGoalCreate(shopQuestionGoalCreateFormResponse: Array<ShopQuestionGoalCreateFormResponse>, allClient?: string, options?: any): AxiosPromise<ShopQuestionGoalCreateOutputResponse> {
            return localVarFp.shopQstGoalCreate(shopQuestionGoalCreateFormResponse, allClient, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GoalApi - object-oriented interface
 * @export
 * @class GoalApi
 * @extends {BaseAPI}
 */
export class GoalApi extends BaseAPI {
    /**
     * 改善目標コピー
     * @summary 改善目標コピー
     * @param {GoalBeforeCopyFormResponse} goalBeforeCopyFormResponse 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public goalBeforeCopy(goalBeforeCopyFormResponse: GoalBeforeCopyFormResponse, allClient?: string, options?: AxiosRequestConfig) {
        return GoalApiFp(this.configuration).goalBeforeCopy(goalBeforeCopyFormResponse, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * QSCチェック設問目標を設定する
     * @summary QSCチェック設問目標設定
     * @param {Array<GoalCreateQscCheckListResponse>} goalCreateQscCheckListResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public goalCreateQscCheck(goalCreateQscCheckListResponse: Array<GoalCreateQscCheckListResponse>, options?: AxiosRequestConfig) {
        return GoalApiFp(this.configuration).goalCreateQscCheck(goalCreateQscCheckListResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 目標設定・達成状況を取得する
     * @summary 目標設定・達成状況取得
     * @param {string} [id] 
     * @param {string} [ym] 
     * @param {string} [monitorId] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public goalResultInfo(id?: string, ym?: string, monitorId?: string, allClient?: string, options?: AxiosRequestConfig) {
        return GoalApiFp(this.configuration).goalResultInfo(id, ym, monitorId, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 目標設定・達成状況をExcel取得する
     * @summary 目標設定・達成状況Excel取得
     * @param {string} [id] 
     * @param {string} [ym] 
     * @param {string} [monitorId] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public goalResultInfoExcel(id?: string, ym?: string, monitorId?: string, allClient?: string, options?: AxiosRequestConfig) {
        return GoalApiFp(this.configuration).goalResultInfoExcel(id, ym, monitorId, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 設問別積上げ目標を設定する
     * @summary 設問別積上げ目標設定
     * @param {Array<QscShopQuestionGoalCreateFormResponse>} qscShopQuestionGoalCreateFormResponse 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public qscShopQstGoalCreate(qscShopQuestionGoalCreateFormResponse: Array<QscShopQuestionGoalCreateFormResponse>, allClient?: string, options?: AxiosRequestConfig) {
        return GoalApiFp(this.configuration).qscShopQstGoalCreate(qscShopQuestionGoalCreateFormResponse, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 目標設定設問カテゴリ一覧を取得する
     * @summary 目標設定設問カテゴリ一覧取得
     * @param {number} [monitorId] モニターベースID
     * @param {string} [ym] 
     * @param {string} [id] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public questionCategoryList1(monitorId?: number, ym?: string, id?: string, allClient?: string, options?: AxiosRequestConfig) {
        return GoalApiFp(this.configuration).questionCategoryList1(monitorId, ym, id, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 設問カテゴリ結果(本部 or 店舗)を取得する
     * @summary 設問カテゴリ目標結果取得(本部 or 店舗)
     * @param {string} [goalPage] 
     * @param {string} [id] 
     * @param {string} [ym] 
     * @param {string} [monitorId] 
     * @param {string} [cat] 
     * @param {string} [achieved] 
     * @param {string} [fromDiff] 
     * @param {string} [toDiff] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public shopCategoryGoalResultList(goalPage?: string, id?: string, ym?: string, monitorId?: string, cat?: string, achieved?: string, fromDiff?: string, toDiff?: string, allClient?: string, options?: AxiosRequestConfig) {
        return GoalApiFp(this.configuration).shopCategoryGoalResultList(goalPage, id, ym, monitorId, cat, achieved, fromDiff, toDiff, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 目標（設問）を登録更新削除する
     * @summary 目標設定登録更新削除（設問カテゴリ）
     * @param {ShopCategoryGoalSettingUpdateFormResponse} shopCategoryGoalSettingUpdateFormResponse 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public shopCategoryGoalSettingUpdate(shopCategoryGoalSettingUpdateFormResponse: ShopCategoryGoalSettingUpdateFormResponse, allClient?: string, options?: AxiosRequestConfig) {
        return GoalApiFp(this.configuration).shopCategoryGoalSettingUpdate(shopCategoryGoalSettingUpdateFormResponse, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 設問目標結果(店舗)を取得する
     * @summary 設問目標結果(店舗)取得
     * @param {string} [id] 
     * @param {string} [ym] 
     * @param {string} [monitorId] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public shopGoalResultList(id?: string, ym?: string, monitorId?: string, allClient?: string, options?: AxiosRequestConfig) {
        return GoalApiFp(this.configuration).shopGoalResultList(id, ym, monitorId, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 目標（設問）を登録更新削除する
     * @summary 目標設定登録更新削除（設問）
     * @param {ShopGoalSettingUpdateFormResponse} shopGoalSettingUpdateFormResponse 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public shopGoalSettingUpdate(shopGoalSettingUpdateFormResponse: ShopGoalSettingUpdateFormResponse, allClient?: string, options?: AxiosRequestConfig) {
        return GoalApiFp(this.configuration).shopGoalSettingUpdate(shopGoalSettingUpdateFormResponse, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 設問カテゴリ目標を設定する
     * @summary 設問カテゴリ目標設定
     * @param {Array<ShopQuestionCategoryGoalCreateFormResponse>} shopQuestionCategoryGoalCreateFormResponse 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public shopQstCatGoalCreate(shopQuestionCategoryGoalCreateFormResponse: Array<ShopQuestionCategoryGoalCreateFormResponse>, allClient?: string, options?: AxiosRequestConfig) {
        return GoalApiFp(this.configuration).shopQstCatGoalCreate(shopQuestionCategoryGoalCreateFormResponse, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 設問別目標を設定する
     * @summary 設問別目標設定
     * @param {Array<ShopQuestionGoalCreateFormResponse>} shopQuestionGoalCreateFormResponse 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public shopQstGoalCreate(shopQuestionGoalCreateFormResponse: Array<ShopQuestionGoalCreateFormResponse>, allClient?: string, options?: AxiosRequestConfig) {
        return GoalApiFp(this.configuration).shopQstGoalCreate(shopQuestionGoalCreateFormResponse, allClient, options).then((request) => request(this.axios, this.basePath));
    }
}

