import { useRecoilValue } from 'recoil';
import { currentUserState } from '../states/atom/CurrentUserState';

export const useCurrentUser = () => {
  const currentUser = useRecoilValue(currentUserState); // グローバルステートからcurrentUserを取り出す
  const isAuthChecking = currentUser === undefined; // ログイン情報を取得中かどうか
  let accountId = 0;
  let clientIdAccountId = 0;
  let clientOrgShopId = '';
  let name = '';
  let clientAdminFlg = false;
  let viewAllFlg = false;
  let userEditFlg = false;
  let goalSetFlg = false;
  let giftViewFlg = false;
  let roiAdminFlg = false;
  let firstPasswordFlg = false;
  let belongCode = '';
  let isBelongClient = false;
  let isBelongOrg = false;
  let isBelongShop = false;
  let topClientId = null;
  if (currentUser) {
    accountId = currentUser!.id;
    clientAdminFlg = currentUser!.clientAdminFlg;
    viewAllFlg = currentUser!.viewAllFlg;
    userEditFlg = currentUser!.userEditFlg;
    goalSetFlg = currentUser!.goalSetFlg;
    giftViewFlg = currentUser!.giftViewFlg;
    roiAdminFlg = currentUser!.roiAdminFlg;
    firstPasswordFlg = currentUser!.firstPasswordFlg;
    belongCode = currentUser!.belongCode;
    name = currentUser!.name;
    // 所属
    const splitValue = belongCode.split(',');
    isBelongClient = splitValue[0] === 'C';
    isBelongOrg = splitValue[0] === 'O';
    isBelongShop = splitValue[0] === 'S';
    topClientId = currentUser!.topClientId;
    clientIdAccountId = currentUser!.topClientId + currentUser!.id;
    clientOrgShopId = splitValue[1] ? splitValue[1] : '';
  }
  return {
    currentUser,
    isAuthChecking,
    name,
    clientAdminFlg,
    viewAllFlg,
    userEditFlg,
    goalSetFlg,
    giftViewFlg,
    roiAdminFlg,
    belongCode,
    isBelongClient,
    isBelongOrg,
    isBelongShop,
    topClientId,
    firstPasswordFlg,
    tobClientName: currentUser?.topClientName ?? '',
    clientIdAccountId,
    accountId,
    clientOrgShopId,
  };
};
