/* eslint-disable react/jsx-boolean-value */
import React, { Dispatch, memo, SetStateAction, useContext, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useRecoilRefresher_UNSTABLE } from 'recoil';
import { AxiosResponse } from 'axios';
import { AccountApi, TobAccountSubRefAddOutputResponse } from '../../../api-client';
import scss from '../../../scss/organisms/SubBelongModal.module.scss';
import {
  mainBelongEditFlgState,
  mainSelectedAuthState,
  mainSelectedBelongState,
  subEditFlgState,
  subSelectedAuthState,
  subSelectedBelongState,
} from '../../../states/atom/RegisterFormState';
import { subBelongGroupSelectSelector } from '../../../states/selector/SubBelongSelectSelector';
import { AddButton } from '../../atoms/Button/UnitButton';
import { SubBelongRadioGroup } from '../../molecules/Form/SubBelongRadioGroup';
import { SelectType, SubBelongSelectGroup } from '../../molecules/Form/SubBelongSelectGroup';
import { useUrlParams } from '../../../hooks/useUrlParams';
import { Alert } from '../../atoms/Alert';
import { useParamClientAll } from '../../../hooks/useParamClientAll';
import { SharedRadioParamContext } from '../../../hooks/useNewRadio';
import { MainBelongFlgContext } from '../../../hooks/useMainBelongFlg';

const SaveResult = {
  /** 失敗 */
  FAILURE: -1,
  /** 成功 */
  SUCCESS: 0,
  /** Save既に登録済み */
  ALREADY_REGISTERED: 1,
};

export type ModalProps = {
  returnValue: (value: string) => void;
  setSubSelectedList: Dispatch<SetStateAction<SelectType[]>>;
  subSelectedList: SelectType[];
  setMainSelectResetFlg: Dispatch<SetStateAction<boolean>>;
  setSubSelectResetFlg: Dispatch<SetStateAction<boolean>>;
  mainSelectResetFlg: boolean;
  subSelectResetFlg: boolean;
  userId: string;
};

export const SubBelongModal2: React.FC<ModalProps> = memo(
  ({
    returnValue,
    setSubSelectedList,
    subSelectedList,
    setMainSelectResetFlg,
    setSubSelectResetFlg,
    mainSelectResetFlg,
    subSelectResetFlg,
    userId,
  }) => {
    const [saveError, setSaveError] = useState(false);
    const { pathname } = useLocation();
    const params = useUrlParams();
    const paramAllClient = useParamClientAll();
    const accountId = params.accountUpdateId === '' ? '' : `:${params.accountUpdateId}`;
    const strAccountId: string | undefined = params.accountUpdateId === '' ? '' : `${params.accountUpdateId}`;
    const strAllClientId = paramAllClient === '' ? '' : `:${paramAllClient}`;
    // 選択したサブ所属のID
    const subSelected = useRecoilValue(subSelectedBelongState(strAccountId));
    // サブで選択した権限
    const [subAuth] = useRecoilState(subSelectedAuthState(strAccountId));
    // メイン所属の権限
    const { mainAuth, setMainAuth } = useContext(SharedRadioParamContext);
    // メイン所属の一覧（グループ選択時）
    const [mainSelectBelong, setMainSelectBelong] = useRecoilState(mainSelectedBelongState(strAccountId));
    // サブ所属の一覧（グループ選択時）
    const subBelongGroups = useRecoilValue(subBelongGroupSelectSelector(mainSelectBelong + accountId + strAllClientId));

    // サブで選択した権限
    const { mainEditFlg, setMainEditFlg } = useContext(MainBelongFlgContext);
    // サブ所属変更したFlg
    const [, setSubEditFlg] = useRecoilState(subEditFlgState(strAccountId));

    // サブ所属の一覧 再取得
    const rsubBelongGroupSelectSelector = useRecoilRefresher_UNSTABLE(
      subBelongGroupSelectSelector(mainSelectBelong + accountId + strAllClientId)
    );
    useEffect(() => {
      rsubBelongGroupSelectSelector();
    }, []);

    const handleAddSub = async () => {
      const api = new AccountApi();
      await api
        .tobAccountSubRefAdd({
          cosId: subSelected,
          tobAccountId: Number(userId),
        })
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .then(async (res: AxiosResponse<TobAccountSubRefAddOutputResponse>) => {
          let values = [];
          if (res != null) {
            const resultRes = res.data as TobAccountSubRefAddOutputResponse;
            // 登録が成功している場合
            if (resultRes.saveResult === SaveResult.SUCCESS) {
              if (subAuth.includes('H')) {
                values = subBelongGroups.organization.filter(
                  (org) => org.organizationId === subSelected && org.organizationId !== mainSelectBelong
                );
                setSubSelectedList([
                  ...subSelectedList,
                  { id: subSelected, value: values[0].organizationName, subRefId: resultRes.data!.id },
                ]);
              } else if (!subAuth.includes('H')) {
                let shopValue = '';
                if (mainAuth === '2') {
                  values = subBelongGroups.contractShop.filter(
                    (o) => o.contractShopId === subSelected && o.contractShopId !== mainSelectBelong
                  );
                  setSubSelectedList([
                    ...subSelectedList,
                    { id: subSelected, value: shopValue, subRefId: resultRes.data!.id },
                  ]);
                  subBelongGroups.contractShop.forEach((o) => {
                    shopValue = o.contractShopName;
                    setSubSelectedList([
                      ...subSelectedList,
                      { id: subSelected, value: shopValue, subRefId: resultRes.data!.id },
                    ]);
                  });
                } else if (mainAuth.includes('H')) {
                  subBelongGroups.contractShop.forEach((o) => {
                    // const key = Object.keys(o.contractShopId).filter((s) => o.[s].id === subSelected)[0];
                    const key = Object.keys(o.contractShopId).filter((s) => o.contractShopId === subSelected)[0];
                    if (key) {
                      shopValue = o.contractShopName;
                      setSubSelectedList([
                        ...subSelectedList,
                        { id: subSelected, value: shopValue, subRefId: resultRes.data!.id },
                      ]);
                    }
                  });
                } else if (mainAuth === '3') {
                  // const shop = subBelongShops.contractShop((s) => s.id === subSelected)[0];
                  // const shop = Object.keys(subBelongShops.contractShop).filter((s) => subBelongShops.contractShop[s].contractShopId === subSelected)[0];
                  // setSubSelectedList([...subSelectedList, { id: shop., value: shop.value }]);
                  subBelongGroups.contractShop.forEach((o) => {
                    // const key = Object.keys(o.contractShopId).filter((s) => o.[s].id === subSelected)[0];
                    const key = Object.keys(o.contractShopId).filter((s) => o.contractShopId === subSelected)[0];
                    if (key) {
                      shopValue = o.contractShopName;
                      setSubSelectedList([
                        ...subSelectedList,
                        { id: subSelected, value: shopValue, subRefId: resultRes.data!.id },
                      ]);
                    }
                  });
                }
              }
              setSubEditFlg(true);
              returnValue('close');
            } else if (resultRes.saveResult === SaveResult.ALREADY_REGISTERED) {
              setSaveError(true);
            }
          }
        });
    };

    const handoleOk = async () => {
      await returnValue('ok');
    };
    const handoleClose = async () => {
      await returnValue('close');
    };

    return (
      <Modal show onHide={handoleClose} className={`${scss.modal} ${scss.modal_sub_belong}`} size="lg">
        <Modal.Header className={scss.modal_header} closeButton>
          <Modal.Title>その他の所属・グループを追加</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-2 d-flex align-items-center">
            {mainAuth === '1' && (
              <span className={`${scss.sub_belong_msg} ${scss.error}`}>
                所属が本社のため、その他の所属・グループは設定できません。
              </span>
            )}
            {mainAuth.includes('H') && (
              <span className={`${scss.sub_belong_msg}`}>所属・グループを下記から選択してください。</span>
            )}
          </div>
          <div className="fw-bold my-2">所属・グループ</div>
          <SubBelongRadioGroup />
          <SubBelongSelectGroup />
          {saveError && (
            <Alert styleName={`${scss.alert_mini_padding} ${scss.m_b_15} ${scss.m_t_15}`} variant="danger">
              <span>既に登録済みの所属先です。</span>
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer className="border-0 m-0">
          {subSelected.length > 0 && subAuth.length > 0 && mainSelectBelong !== subSelected && (
            <AddButton label="追加" onClick={handleAddSub} disable={false} />
          )}
          {subSelected.length === 0 && subAuth.length === 0 && mainSelectBelong === subSelected && (
            <AddButton label="追加" onClick={handleAddSub} disable={true} />
          )}
        </Modal.Footer>
      </Modal>
    );
  }
);
