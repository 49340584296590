import React, { useEffect, useLayoutEffect, useState } from 'react';
import { FaUserLock } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import { AxiosResponse } from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { readOnlySelector, useRecoilRefresher_UNSTABLE, useRecoilState } from 'recoil';
import Select, { GroupBase, SingleValue } from 'react-select';
import { ClientAllOutputResponse, SelectBoxApi } from '../../../api-client';
import {
  allClientState,
  monitorState,
  selectShopListState,
  shopState,
  ymState,
  roiAdminSelectClientState,
} from '../../../states/atom/CommonPageState';
import { urlParamConv } from '../../../utils/functions';
import { useUrlParams } from '../../../hooks/useUrlParams';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import selectScss from '../../../scss/molecules/selectbox.module.scss';
import scss from '../../../scss/pages/enquete.module.scss';

interface SelectAllClientOption {
  value: string;
  label: string;
}

export const CorporateSelect: React.VFC = () => {
  const history = useHistory();
  const params = useUrlParams();
  const user = useCurrentUser();
  const { pathname, search } = useLocation();
  const [allClient, setAllClient] = useRecoilState(allClientState(''));
  const [ClientList, setClientList] = useState<ClientAllOutputResponse[]>([{ id: 0, name: '' }]);
  const [clientAllSelect, setClientAllSelect] = useState<SelectAllClientOption>();
  const [clientAllOptions, setClientAllOptions] = useState<Array<SelectAllClientOption>>([]);
  const [shopListState, setShopListState] = useRecoilState(selectShopListState);
  const reefreshShopState = useRecoilRefresher_UNSTABLE(shopState(pathname));
  const reefreshYm = useRecoilRefresher_UNSTABLE(ymState(pathname));
  const reefreshMonitor = useRecoilRefresher_UNSTABLE(monitorState(pathname));
  const reefreshShopStateAlpha = useRecoilRefresher_UNSTABLE(shopState(pathname + allClient));
  const reefreshYmAlpha = useRecoilRefresher_UNSTABLE(ymState(pathname + allClient));
  const reefreshMonitorAlpha = useRecoilRefresher_UNSTABLE(monitorState(pathname + allClient));
  const [adminRoiSelectClientState, setAdminRoiSelectClientState] = useRecoilState(roiAdminSelectClientState(''));
  const api = new SelectBoxApi();
  let caOptions: Array<SelectAllClientOption>;

  const getAllClientId = (): string => {
    let allClientId = '';
    const paramAllClient = params.allClient === '' ? undefined : params.allClient;
    // パラメータに指定がある場合はパラメータ優先
    if (paramAllClient !== '' && paramAllClient !== undefined) {
      allClientId = paramAllClient;
    } else {
      allClientId = String(user.topClientId);
    }
    return allClientId;
  };
  const handleSearchCorporate = async () => {
    reefreshShopState();
    reefreshYm();
    reefreshMonitor();
    reefreshShopStateAlpha();
    reefreshYmAlpha();
    reefreshMonitorAlpha();
    setShopListState([]);
    const allClientId = getAllClientId();
    setAllClient(allClientId);
    if (ClientList.length === 1) {
      api.clientAll().then((res: AxiosResponse<ClientAllOutputResponse[]>) => {
        // setAllClient(String(res.data[0].id));
        // setAllClient(allClientId);
        setClientList(res.data);
        // react-selectようにデータ整形
        caOptions = res.data.map((v: ClientAllOutputResponse) => {
          return { label: v.name, value: v.id.toString() };
        });
        setClientAllOptions(caOptions);

        const selectCaption = caOptions.find((option) => option.value === allClientId);
        setAdminRoiSelectClientState((adminRoiSelectClient) => ({
          ...adminRoiSelectClient,
          roiAdminSelectClientId: selectCaption?.value,
          roiAdminSelectClientName: selectCaption?.label,
        }));

        // 初期値
        setClientAllSelect(selectCaption);
      });
    }
  };

  useLayoutEffect(() => {
    handleSearchCorporate();
  }, []);

  useLayoutEffect(() => {
    handleSearchCorporate();
  }, [pathname]);

  useEffect(() => {
    if (allClient !== '') {
      // 選択したClientを保存する
      setAllClient(allClient);
      // パラメータにallClientが存在しない場合
      if (!params.allClient) {
        history.push({
          pathname,
          search: urlParamConv(search, 'allClient', allClient, true),
        });
      }
    }
  }, [allClient]);
  const handleChange = (e: SingleValue<{ value: string; label: string }>) => {
    // const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    reefreshShopState();
    reefreshYm();
    reefreshMonitor();
    reefreshShopStateAlpha();
    reefreshYmAlpha();
    reefreshMonitorAlpha();
    setShopListState([]);
    setClientAllSelect(e!);
    setAdminRoiSelectClientState((adminRoiSelectClient) => ({
      ...adminRoiSelectClient,
      roiAdminSelectClientId: e!.value,
      roiAdminSelectClientName: e!.label,
    }));

    history.push({
      pathname,
      search: urlParamConv(search, 'allClient', e!.value.toString(), true),
    });
  };

  return (
    <div className="d-flex align-items-center">
      <IconContext.Provider value={{ size: '2.0rem' }}>
        <FaUserLock className="mx-2" />
        <Select
          value={clientAllSelect}
          data-testid="select-all-client"
          options={clientAllOptions}
          onChange={handleChange}
          className={`${selectScss.select_item} ${scss.shop_index} ${scss.react_select_item} ${selectScss.corporate_select} `}
        />
      </IconContext.Provider>
    </div>
  );
};
