/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew ToB向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { TobAccessLogShopCntOutputResponse } from '../domains';
/**
 * AccessLogApi - axios parameter creator
 * @export
 */
export const AccessLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 該当月の店舗のアクセス状況をエクセルで取得する
         * @summary アクセス状況エクセル出力
         * @param {string} [id] クライアント,組織,店舗ID
         * @param {string} [ym] 年月
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessLogCntExcel: async (id?: string, ym?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/access-log-cnt-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 該当月の店舗のアクセス状況を取得する
         * @summary アクセス状況
         * @param {string} [id] クライアント,組織,店舗ID
         * @param {string} [ym] 年月
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessLogShopCnt: async (id?: string, ym?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/access-log-shop-cnt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccessLogApi - functional programming interface
 * @export
 */
export const AccessLogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccessLogApiAxiosParamCreator(configuration)
    return {
        /**
         * 該当月の店舗のアクセス状況をエクセルで取得する
         * @summary アクセス状況エクセル出力
         * @param {string} [id] クライアント,組織,店舗ID
         * @param {string} [ym] 年月
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accessLogCntExcel(id?: string, ym?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accessLogCntExcel(id, ym, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 該当月の店舗のアクセス状況を取得する
         * @summary アクセス状況
         * @param {string} [id] クライアント,組織,店舗ID
         * @param {string} [ym] 年月
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accessLogShopCnt(id?: string, ym?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TobAccessLogShopCntOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accessLogShopCnt(id, ym, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccessLogApi - factory interface
 * @export
 */
export const AccessLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccessLogApiFp(configuration)
    return {
        /**
         * 該当月の店舗のアクセス状況をエクセルで取得する
         * @summary アクセス状況エクセル出力
         * @param {string} [id] クライアント,組織,店舗ID
         * @param {string} [ym] 年月
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessLogCntExcel(id?: string, ym?: string, allClient?: string, options?: any): AxiosPromise<string> {
            return localVarFp.accessLogCntExcel(id, ym, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 該当月の店舗のアクセス状況を取得する
         * @summary アクセス状況
         * @param {string} [id] クライアント,組織,店舗ID
         * @param {string} [ym] 年月
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessLogShopCnt(id?: string, ym?: string, allClient?: string, options?: any): AxiosPromise<TobAccessLogShopCntOutputResponse> {
            return localVarFp.accessLogShopCnt(id, ym, allClient, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccessLogApi - object-oriented interface
 * @export
 * @class AccessLogApi
 * @extends {BaseAPI}
 */
export class AccessLogApi extends BaseAPI {
    /**
     * 該当月の店舗のアクセス状況をエクセルで取得する
     * @summary アクセス状況エクセル出力
     * @param {string} [id] クライアント,組織,店舗ID
     * @param {string} [ym] 年月
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessLogApi
     */
    public accessLogCntExcel(id?: string, ym?: string, allClient?: string, options?: AxiosRequestConfig) {
        return AccessLogApiFp(this.configuration).accessLogCntExcel(id, ym, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 該当月の店舗のアクセス状況を取得する
     * @summary アクセス状況
     * @param {string} [id] クライアント,組織,店舗ID
     * @param {string} [ym] 年月
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessLogApi
     */
    public accessLogShopCnt(id?: string, ym?: string, allClient?: string, options?: AxiosRequestConfig) {
        return AccessLogApiFp(this.configuration).accessLogShopCnt(id, ym, allClient, options).then((request) => request(this.axios, this.basePath));
    }
}

